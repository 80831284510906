import moment, { Moment } from "moment";
import { PresetDate, EventValue, RangeValue } from "rc-picker/lib/interface";
import { DOCUMENT_STATUS } from "../../../utils";
import './copilots-details.scss';
import { STATUS_COLORS } from "./constants";

export const getLastWeekDates = () => {
  const startDateOfCurrentWeek = moment().startOf("week");
  const endDateOfLastWeek = startDateOfCurrentWeek.clone().subtract(1, "day");
  const startDateOfLastWeek = endDateOfLastWeek.clone().subtract(6, "days");
  return [startDateOfLastWeek, endDateOfLastWeek] as [EventValue<Moment>, EventValue<Moment>];
};

export const getLastMonthDates = () => {
  const startDateOfCurrentMonth = moment().startOf("month");
  const endDateOfLastMonth = startDateOfCurrentMonth.clone().subtract(1, "day");
  const startDateOfLastMonth = endDateOfLastMonth.clone().startOf("month");
  return [startDateOfLastMonth, endDateOfLastMonth] as [EventValue<Moment>, EventValue<Moment>];
};

export const rangePresets: PresetDate<[EventValue<Moment>, EventValue<Moment>]>[] = [
  {
    label: "Today",
    value: [moment().startOf("day"), moment().endOf("day")],
  },
  {
    label: "Last 7 Days",
    value: [moment().add(-7, "d"), moment()],
  },
  {
    label: "Last Week",
    value: getLastWeekDates(),
  },
  {
    label: "Last 30 Days",
    value: [moment().add(-30, "d"), moment()],
  },
  {
    label: "Last Month",
    value: getLastMonthDates(),
  },
];

export const getClass = (value: string) => {
  switch (value.toUpperCase()) {
    case DOCUMENT_STATUS.COMPLETED:
    case DOCUMENT_STATUS.SUCCEEDED:
      return STATUS_COLORS.COMPLETED;
    case DOCUMENT_STATUS.ERROR:
      return STATUS_COLORS.ERROR;
    case DOCUMENT_STATUS.ON_HOLD:
      return STATUS_COLORS.ON_HOLD;
    case DOCUMENT_STATUS.PROCESSING:
      return STATUS_COLORS.PROCESSING;
    case DOCUMENT_STATUS.FETCHING:
      return STATUS_COLORS.FETCHING;
    default:
      return STATUS_COLORS.DEFAULT;
  }
};




export const getPCPStatusClass = (value: string) => {
  switch (value) {
    case DOCUMENT_STATUS.COMPLETED:
      return "success";
    case DOCUMENT_STATUS.SUCCEEDED:
      return "success";
    case DOCUMENT_STATUS.ERROR:
      return "error";
    case DOCUMENT_STATUS.ON_HOLD:
      return "blue";
    case DOCUMENT_STATUS.PROCESSING:
      return "warning";
    default:
      return "";
  }
};