import React, { useState } from "react";
import { Button, Progress } from "antd";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { LoadingProcessData } from "react-pdf";
import { AppPagination } from "../app-pagination/AppPagination";
import "./pdf-content.scss";
import { downloadPDF } from "../../utils";
import { DownloadOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";

interface Props {
  pageInfo?: {
    width?: number;
    height?: number;
    scale?: number;
  };
  displayPagination?: boolean;
  currPage: number;
  setCurrPage: (page: number) => void;
  pdf: any;
}

export const PdfViewer = (props: Props) => {

  const { pdf, pageInfo, displayPagination, currPage, setCurrPage } = props;

  const [totalPages, setTotalPages] = useState(0);
  const [loadSuccess, setLoadSuccess] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState({
    loaded: 0,
    total: 100,
  } as LoadingProcessData);
  const [scale, setScale] = useState(pageInfo?.scale || 1);
  const minScale = 0.5;
  const maxScale = 2;
  const displayBottomPagination = displayPagination && loadSuccess;
  const onDocumentLoadSuccess = (params: any) => {
    const { numPages } = params;
    setTotalPages(numPages);
    setLoadSuccess(true);
  };

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, maxScale));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, minScale));
  };

  const handleFileDownload = async () => {
      await downloadPDF(pdf);
  };

  return (
    <div className="e-pdf e-content">
      <div className="button-container">
        <Button icon={<ZoomOutOutlined />} onClick={handleZoomOut} disabled={scale <= minScale} />
        <span>{scale ? `${Math.round(scale * 100)}%` : "N/A"}</span>
        <Button icon={<ZoomInOutlined />} onClick={handleZoomIn} disabled={scale >= maxScale} />
        <Button icon={<DownloadOutlined />} onClick={handleFileDownload} />
      </div>
      {displayBottomPagination && (
        <div className="flex-center e-pdf-pagination">
          <div className="flex-center e-pdf-pagination">
            <AppPagination
              total={totalPages * 10}
              simple
              current={currPage}
              onChange={(page) => setCurrPage(page)}
              showSizeChanger={false}
            />
          </div>
          <div className="button-container">
            <Button
              icon={<ZoomOutOutlined />}
              onClick={handleZoomOut}
              disabled={scale <= minScale}
            />
            <span>{scale ? `${Math.round(scale * 100)}%` : "N/A"}</span>
            <Button icon={<ZoomInOutlined />} onClick={handleZoomIn} disabled={scale >= maxScale} />
            <Button icon={<DownloadOutlined />} onClick={handleFileDownload} />
          </div>
        </div>
      )}
      <div className="pdf-container">
        <Document
          className="pdf"
          file={pdf}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={() => (
            <Progress percent={Math.ceil((loadingProgress.loaded * 100) / loadingProgress.total)} />
          )}
          onLoadProgress={(e) => setLoadingProgress(e)}
        >
          <Page
            pageNumber={currPage}
            height={pageInfo?.height}
            width={pageInfo?.width}
            scale={scale}
          />
        </Document>
      </div>
    </div>
  );
};
