import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

interface IModal {
  [name: string]: boolean;
}

interface IDateRange {
  fromDate: string | null; toDate: string | null;
}

interface UiSlice {
  modal: IModal;
  isDeleteConfirmed: boolean;
  selectedDateRange: IDateRange;
}

const initialFromDate = moment().subtract(2, "months").format("YYYY-MM-DD");
const initialToDate = moment().format("YYYY-MM-DD");

export const uiInitialState: UiSlice = {
  modal: {} as IModal,
  isDeleteConfirmed: false,
  selectedDateRange: { fromDate: initialFromDate, toDate: initialToDate },
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: uiInitialState,
  reducers: {
    openModal: (state: UiSlice, { payload }: PayloadAction<string>) => {
      state.modal[payload] = true;
    },
    closeModal: (state: UiSlice, { payload }: PayloadAction<string>) => {
      state.modal[payload] = false;
    },
    updateDeleteConfirmation: (state: UiSlice, { payload }: PayloadAction<boolean>) => {
      state.isDeleteConfirmed = payload;
    },
    setSelectedDateRange: (state: UiSlice, { payload }: PayloadAction<IDateRange>) => {
      state.selectedDateRange = payload;
    },
    clearSelectedDateRange: (state: UiSlice) => {
      state.selectedDateRange = { fromDate: "", toDate: "" };
    },
  },
});

export const {
  openModal,
  closeModal,
  updateDeleteConfirmation,
  setSelectedDateRange,
  clearSelectedDateRange
} = uiSlice.actions;

export default uiSlice.reducer;
