import React, { useEffect, useMemo, useState } from "react";
import "../../billing-dashboard/billing-dashboard-container.scss";
import {
  COPILOTS_NAME,
  IApplication,
  STATUS_CODE,
  TOKENS_USAGE_APPS,
  formatLargeNumber,
  openNotificationWithIcon,
  tokenBillingResponseDateWise,
} from "../../../utils";
import { Column, ColumnConfig } from "@ant-design/charts";
import { NotAccessibleFallback } from "../../../components";
import moment, { Moment } from "moment";
import "antd/dist/reset.css";
import { TimeRangePickerProps } from "antd";
import { Overview } from "./Overview";
import { DatePicker } from "../../../components";
import { tokenBillingService } from "../../../api/tokenBilling";
import { SummaryDataAndGraphs } from "../../summary-data";

const { RangePicker } = DatePicker;
interface Props {
  application?: IApplication;
}

let pegaUsedTokens = 0;
let hedisUsedTokens = 0;
let piUsedTokens = 0;
const initalTokenData: ITokenUsage[] = [];
const hedisInitalTokenData: ITokenUsage[] = [];
const pegaInitalTokenData: ITokenUsage[] = [];
const piInitalTokenData: ITokenUsage[] = [];
const hedisInitalTokenDataFull: ITokenUsage[] = [];
const pegaInitalTokenDataFull: ITokenUsage[] = [];
const piInitalTokenDataFull: ITokenUsage[] = [];
let totalTokensHedis = 0;
let documentsProcessedHedis = 0;
let ocrCallsHedis = 0;
let openAITokensHedis = 0;
let totalTokensPega = 0;
let documentsProcessedPega = 0;
let ocrCallsPega = 0;
let openAITokensPega = 0;
let totalTokensPI = 0;
let documentsProcessedPI = 0;
let ocrCallsPI = 0;
let openAITokensPI = 0;
let questionsAnsweredPI = 0;
let feedbacksPI = 0;


tokenBillingResponseDateWise.data.data.forEach((item, index) => {
  item.token_count?.forEach((entry) => {
    switch (entry.service_name) {
      case TOKENS_USAGE_APPS.HEDIS:
        hedisUsedTokens += entry.token_count;
        totalTokensHedis += entry.token_count;
        hedisInitalTokenData.push({
          month: item.date,
          type: "Tokens",
          value: entry.token_count,
        });
        hedisInitalTokenDataFull.push({
          month: item.date,
          type: "Tokens",
          value: entry.token_count,
        });
        break;
      case TOKENS_USAGE_APPS.PEGA:
        pegaUsedTokens += entry.token_count;
        totalTokensPega += entry.token_count;
        pegaInitalTokenData.push({
          month: item.date,
          type: "Tokens",
          value: entry.token_count,
        });
        pegaInitalTokenDataFull.push({
          month: item.date,
          type: "Tokens",
          value: entry.token_count,
        });
        break;
      case TOKENS_USAGE_APPS.PI:
        piUsedTokens += entry.token_count;
        totalTokensPI += entry.token_count;
        piInitalTokenData.push({
          month: item.date,
          type: "Tokens",
          value: entry.token_count,
        });
        piInitalTokenDataFull.push({
          month: item.date,
          type: "Tokens",
          value: entry.token_count,
        });
        break;
    }
    initalTokenData.push({
      month: item.date,
      type: entry.service_name,
      value: entry.token_count,
    });
  });
  item.documents_processed.forEach((entry) => {
    switch (entry.service_name) {
      case TOKENS_USAGE_APPS.HEDIS:
        hedisUsedTokens += entry.token_count;
        documentsProcessedHedis += entry.token_count;
        hedisInitalTokenDataFull.push({
          month: item.date,
          type: "Documents Processed",
          value: entry.token_count,
        });
        break;
      case TOKENS_USAGE_APPS.PEGA:
        pegaUsedTokens += entry.token_count;
        documentsProcessedPega += entry.token_count;
        pegaInitalTokenDataFull.push({
          month: item.date,
          type: "Documents Processed",
          value: entry.token_count,
        });
        break;
      case TOKENS_USAGE_APPS.PI:
        piUsedTokens += entry.token_count;
        documentsProcessedPI += entry.token_count;
        piInitalTokenDataFull.push({
          month: item.date,
          type: "Documents Processed",
          value: entry.token_count,
        });
        break;
    }
  });
  item.ocr_calls.forEach((entry) => {
    switch (entry.service_name) {
      case TOKENS_USAGE_APPS.HEDIS:
        hedisUsedTokens += entry.token_count;
        ocrCallsHedis += entry.token_count;
        hedisInitalTokenDataFull.push({
          month: item.date,
          type: "OCR Calls",
          value: entry.token_count,
        });
        break;
      case TOKENS_USAGE_APPS.PEGA:
        pegaUsedTokens += entry.token_count;
        ocrCallsPega += entry.token_count;
        pegaInitalTokenDataFull.push({
          month: item.date,
          type: "OCR Calls",
          value: entry.token_count,
        });
        break;
      case TOKENS_USAGE_APPS.PI:
        piUsedTokens += entry.token_count;
        ocrCallsPI += entry.token_count;
        piInitalTokenDataFull.push({
          month: item.date,
          type: "OCR Calls",
          value: entry.token_count,
        });
        break;
    }
  });
  item.open_ai_tokens.forEach((entry) => {
    switch (entry.service_name) {
      case TOKENS_USAGE_APPS.HEDIS:
        hedisUsedTokens += entry.token_count;
        openAITokensHedis += entry.token_count;
        hedisInitalTokenDataFull.push({
          month: item.date,
          type: "Open AI Tokens",
          value: entry.token_count,
        });
        break;
      case TOKENS_USAGE_APPS.PEGA:
        pegaUsedTokens += entry.token_count;
        openAITokensPega += entry.token_count;
        pegaInitalTokenDataFull.push({
          month: item.date,
          type: "Open AI Tokens",
          value: entry.token_count,
        });
        break;
      case TOKENS_USAGE_APPS.PI:
        piUsedTokens += entry.token_count;
        openAITokensPI += entry.token_count;
        piInitalTokenDataFull.push({
          month: item.date,
          type: "Open AI Tokens",
          value: entry.token_count,
        });
        break;
    }
  });
  item.questions_answered.forEach((entry) => {
    switch (entry.service_name) {
      case TOKENS_USAGE_APPS.PI:
        piUsedTokens += entry.token_count;
        questionsAnsweredPI += entry.token_count;
        piInitalTokenDataFull.push({
          month: item.date,
          type: "Questions Answered",
          value: entry.token_count,
        });
        break;
    }
  });
  item.feedbacks.forEach((entry) => {
    switch (entry.service_name) {
      case TOKENS_USAGE_APPS.PI:
        piUsedTokens += entry.token_count;
        feedbacksPI += entry.token_count;
        piInitalTokenDataFull.push({
          month: item.date,
          type: "Feedback",
          value: entry.token_count,
        });
        break;
    }
  });
});


export const Utilization = (props: Props) => {
  const { application } = props;

  const [loading, setLoading] = useState(false);
  const [tokenUsageData, setTokenUsageData] = useState(initalTokenData as unknown as ITokenUsage[]);
  const [hedisTokenUsageData, setHedisTokenUsageData] = useState(
    hedisInitalTokenData as unknown as ITokenUsage[]
  );
  const [pegaTokenUsageData, setPegaTokenUsageData] = useState(
    pegaInitalTokenData as unknown as ITokenUsage[]
  );
  const [piTokenUsageData, setPITokenUsageData] = useState(
    piInitalTokenData as unknown as ITokenUsage[]
  );
  const [tokenUsedThisMonth, setTokenUsedThisMonth] = useState("0");
  const [questionsAnswered, setQuestionsAnswered] = useState("0");
  const [feedBacksCollected, setFeedbacksCollected] = useState("0");
  const [ocrCalls, setOcrCalls] = useState("0");
  const [documentsData, setDocumentsData] = useState("0");
  const [openAITokens, setOpenAITokens] = useState("0");
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [component, setComponent] = useState(<></>);
  const [overviewComponent, setOverviewComponent] = useState(<></>);
  const [startMonth, setStartMonth] = useState('2000-01-01');
  const todayDate = new Date();
  const [endMonth, setEndMonth] = useState(todayDate.toISOString().split('T')[0]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // const { data } = await tokenBillingService.getData(application?.title);
        const { data } = tokenBillingResponseDateWise;
        const tokenData: ITokenUsage[] = [];
        let tokenThisMonth = 0;
        data.data.forEach((item, index) => {
          item.token_count?.forEach((entry) => {
            if (index === data.data.length - 1) tokenThisMonth += entry.token_count;
            tokenData.push({
              month: item.date,
              type: entry.service_name,
              value: entry.token_count,
            });
          });
        });
        setTokenUsedThisMonth(formatLargeNumber(tokenThisMonth));
        setTokenUsageData(tokenData);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [application]);

  const hedisConfig: ColumnConfig = useMemo(() => {
    return {
      loading,
      data: hedisTokenUsageData,
      isStack: true,
      xField: "month",
      yField: "value",
      seriesField: "type",
      label: {
        content: "",
        position: "middle",
        layout: [
          { type: "interval-adjust-position" },
          { type: "element-active" },
          { type: "element-highlight-by-color" },
        ],
      },
      columnStyle: {
        radius: 0,
      },
      color: ["#F43F5F", "#F59E0C", "#3C82F6"],
    };
  }, [loading, hedisTokenUsageData]);

  const piConfig: ColumnConfig = useMemo(() => {
    return {
      loading,
      data: piTokenUsageData,
      isStack: true,
      xField: "month",
      yField: "value",
      seriesField: "type",
      label: {
        content: "",
        position: "middle",
        layout: [
          { type: "interval-adjust-position" },
          { type: "element-active" },
          { type: "element-highlight-by-color" },
        ],
      },
      columnStyle: {
        radius: 0,
      },
      color: ["#F43F5F", "#F59E0C", "#3C82F6"],
    };
  }, [loading, piTokenUsageData]);

  const pegaConfig: ColumnConfig = useMemo(() => {
    return {
      loading,
      data: pegaTokenUsageData,
      isStack: true,
      xField: "month",
      yField: "value",
      seriesField: "type",
      label: {
        content: "",
        position: "middle",
        layout: [
          { type: "interval-adjust-position" },
          { type: "element-active" },
          { type: "element-highlight-by-color" },
        ],
      },
      columnStyle: {
        radius: 0,
      },
      color: ["#F43F5F", "#F59E0C", "#3C82F6"],
    };
  }, [loading, pegaTokenUsageData]);

  const convertDateString = (dateString: string, delimiter = " ") => {
    const [year, month] = dateString.split(delimiter) || [];
    if (year && month) {
      const date = moment(`${year}-${month}-01`);
      return date.isValid() ? date : null;
    }
    return null;
  };

  const convertDateStringNew = (dateString: string, delimiter = "-") => {
    const date = moment(dateString);
    return date.isValid() ? date : null;
  };

  const getTotalTokensValue = (data: ITokenUsage[], tokenType: string) => {
    const totalTokens: number = data.reduce((accumulator, currentValue) => {
      if (currentValue.type === tokenType) {
        return accumulator + currentValue.value;
      }
      return accumulator;
    }, 0);
    return totalTokens;
  }

  useEffect(() => {
    let res = <></>;
    let overview = <></>;
    switch (application?.title) {
      case TOKENS_USAGE_APPS.HEDIS:
        setTokenUsedThisMonth(String(formatLargeNumber(totalTokensHedis)));
        setDocumentsData(String(formatLargeNumber(documentsProcessedHedis)));
        setOcrCalls(String(formatLargeNumber(ocrCallsHedis)));
        setOpenAITokens(String(formatLargeNumber(openAITokensHedis)));
        res = <Column {...hedisConfig} />;
        break;
      case TOKENS_USAGE_APPS.PEGA:
        setTokenUsedThisMonth(String(formatLargeNumber(totalTokensPega)));
        setDocumentsData(String(formatLargeNumber(documentsProcessedPega)));
        setOcrCalls(String(formatLargeNumber(ocrCallsPega)));
        setOpenAITokens(String(formatLargeNumber(openAITokensPega)));
        res = <Column {...pegaConfig} />;
        break;
      case TOKENS_USAGE_APPS.PI:
        setTokenUsedThisMonth(String(formatLargeNumber(totalTokensPI)));
        setDocumentsData(String(formatLargeNumber(documentsProcessedPI)));
        setOcrCalls(String(formatLargeNumber(ocrCallsPI)));
        setOpenAITokens(String(formatLargeNumber(openAITokensPI)));
        setQuestionsAnswered(String(formatLargeNumber(questionsAnsweredPI)));
        setFeedbacksCollected(String(formatLargeNumber(feedbacksPI)));
        res = <Column {...piConfig} />;
        break;
    }
    overview = <Overview copilotName={application?.title} documents={documentsData} ocrCalls={ocrCalls} openAITokens={openAITokens} totalTokens={tokenUsedThisMonth} feedbacks={feedBacksCollected} qaTokens={questionsAnswered} />
    setOverviewComponent(overview);
    setComponent(res);
  }, [application, pegaTokenUsageData, piTokenUsageData, hedisTokenUsageData]);

  useEffect(() => {
    const formattedStartDate = convertDateStringNew(startMonth, "-");
    const formattedEndDate = convertDateStringNew(endMonth, "-");
    let tokenData: any[] = [];

    if (application?.title === TOKENS_USAGE_APPS.HEDIS) {
      tokenData = hedisInitalTokenData;
    } else if (application?.title === TOKENS_USAGE_APPS.PEGA) {
      tokenData = pegaInitalTokenData;
    } else if (application?.title === TOKENS_USAGE_APPS.PI) {
      tokenData = piInitalTokenData;
    }
    const filteredData = tokenData.filter((item) => {
      const itemDate = convertDateStringNew(item.month);
      return (
        itemDate !== null &&
        formattedStartDate !== null &&
        formattedEndDate !== null &&
        itemDate.isSameOrAfter(formattedStartDate) &&
        itemDate.isSameOrBefore(formattedEndDate)
      );
    });
    // Set the filtered data in the state
    if (application?.title === TOKENS_USAGE_APPS.HEDIS) {
      const hedisFullFiltered = hedisInitalTokenDataFull.filter((item) => {
        const itemDate = convertDateStringNew(item.month);
        return (
          itemDate !== null &&
          formattedStartDate !== null &&
          formattedEndDate !== null &&
          itemDate.isSameOrAfter(formattedStartDate) &&
          itemDate.isSameOrBefore(formattedEndDate)
        );
      });
      totalTokensHedis = getTotalTokensValue(hedisFullFiltered, 'Tokens');
      documentsProcessedHedis = getTotalTokensValue(hedisFullFiltered, 'Documents Processed');
      ocrCallsHedis = getTotalTokensValue(hedisFullFiltered, 'OCR Calls');
      openAITokensHedis = getTotalTokensValue(hedisFullFiltered, 'Open AI Tokens');
      setTokenUsedThisMonth(String(formatLargeNumber(totalTokensHedis)));
      setDocumentsData(String(formatLargeNumber(documentsProcessedHedis)));
      setOcrCalls(String(formatLargeNumber(ocrCallsHedis)));
      setOpenAITokens(String(formatLargeNumber(openAITokensHedis)));
      setHedisTokenUsageData(filteredData);
    } else if (application?.title === TOKENS_USAGE_APPS.PEGA) {
      const pegaFullFiltered = pegaInitalTokenDataFull.filter((item) => {
        const itemDate = convertDateStringNew(item.month);
        return (
          itemDate !== null &&
          formattedStartDate !== null &&
          formattedEndDate !== null &&
          itemDate.isSameOrAfter(formattedStartDate) &&
          itemDate.isSameOrBefore(formattedEndDate)
        );
      });
      totalTokensPega = getTotalTokensValue(pegaFullFiltered, 'Tokens');
      documentsProcessedPega = getTotalTokensValue(pegaFullFiltered, 'Documents Processed');
      ocrCallsPega = getTotalTokensValue(pegaFullFiltered, 'OCR Calls');
      openAITokensPega = getTotalTokensValue(pegaFullFiltered, 'Open AI Tokens');
      setTokenUsedThisMonth(String(formatLargeNumber(totalTokensPega)));
      setDocumentsData(String(formatLargeNumber(documentsProcessedPega)));
      setOcrCalls(String(formatLargeNumber(ocrCallsPega)));
      setOpenAITokens(String(formatLargeNumber(openAITokensPega)));
      setPegaTokenUsageData(filteredData);
    } else if (application?.title === TOKENS_USAGE_APPS.PI) {
      const piFullFiltered = piInitalTokenDataFull.filter((item) => {
        const itemDate = convertDateStringNew(item.month);
        return (
          itemDate !== null &&
          formattedStartDate !== null &&
          formattedEndDate !== null &&
          itemDate.isSameOrAfter(formattedStartDate) &&
          itemDate.isSameOrBefore(formattedEndDate)
        );
      });
      totalTokensPI = getTotalTokensValue(piFullFiltered, 'Tokens');
      documentsProcessedPI = getTotalTokensValue(piFullFiltered, 'Documents Processed');
      ocrCallsPI = getTotalTokensValue(piFullFiltered, 'OCR Calls');
      openAITokensPI = getTotalTokensValue(piFullFiltered, 'Open AI Tokens');
      questionsAnsweredPI = getTotalTokensValue(piFullFiltered, 'Questions Answered');
      feedbacksPI = getTotalTokensValue(piFullFiltered, 'Feedback');
      setTokenUsedThisMonth(String(formatLargeNumber(totalTokensPI)));
      setDocumentsData(String(formatLargeNumber(documentsProcessedPI)));
      setOcrCalls(String(formatLargeNumber(ocrCallsPI)));
      setOpenAITokens(String(formatLargeNumber(openAITokensPI)));
      setQuestionsAnswered(String(formatLargeNumber(questionsAnsweredPI)));
      setFeedbacksCollected(String(formatLargeNumber(feedbacksPI)));
      setPITokenUsageData(filteredData);
    }
  }, [startMonth, endMonth])

  if (!isAuthorized) {
    return <NotAccessibleFallback />;
  }

  const handleDateChange = async (dates: any, dateStrings: any) => {
    if (dateStrings && Array.isArray(dateStrings) && dates) {
      const [startDate, endDate] = dates || [];
      const [startString, endString] = dateStrings;
      setStartMonth(startString);
      setEndMonth(endString);
    }
    else {
      setStartMonth('2000-01-01');
      setEndMonth(todayDate.toISOString().split('T')[0]);
    }
  };

  return (
    <div className="billing-dashboard-container">
      <div className="ant-card">
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "2em" }}>
          <SummaryDataAndGraphs copilotName={application?.title} hideExtractionData={true}/>
        </div>
      </div>
    </div>
  );
};
export default Utilization;
