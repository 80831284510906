import React from "react";
import { EvidencePageResponseInfo, Form, PegaPageDataSummary } from "../../../api";
import ReactJson from "react-json-view";

interface Props {
  pageInfo?: any;
}

export const EvidenceContentJSON = ({ pageInfo }: Props) => {
  if (!pageInfo) {
    return <div className="e-text">No information available</div>;
  }
  return (
    <div className="e-text">
      {/* <h2>{pageInfo.heading}</h2> */}
      <div className="e-paragraph">
        <ReactJson src={JSON.parse(JSON.stringify(pageInfo))} enableClipboard />
      </div>
    </div>
  );
};
