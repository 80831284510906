import React, { useEffect, useState } from "react";
import { EvidencePageResponseInfo, PegaPageDataSummary } from "../../../../api";
import { ENHANCED_VIEW_TABS_PCP, ENHANCED_VIEW_TABS_PEGA } from "../../EvidenceViewerContainer";
import { isEmptyObject } from "../../../../utils";
import PcpSummaryComponent, { PcpData } from "./EvidenceViewerSummaryPcp";
import { EvidenceContentJSON } from "../EvidenceContentJSON";

interface Props {
  pageInfo: PegaPageDataSummary;
  loading: boolean;
  selectedTab: string;
  searchKeywords: string;
  selectedLegends: string[];
  fileId: string;
  pageData: any;
}

export const EvidenceContentPcp = ({
  pageInfo,
  loading,
  selectedTab,
  searchKeywords,
  selectedLegends,
  fileId,
  pageData,
}: Props) => {
  const [component, setComponent] = useState(<></>);


  useEffect(() => {
    if (!isEmptyObject(pageInfo)) {
      let res = <></>;
      switch (selectedTab) {
        case ENHANCED_VIEW_TABS_PCP.SUMMARY:
          res = (
            <PcpSummaryComponent
              data={pageData} 
              fileId={fileId}
            />
          );
          break;
          case ENHANCED_VIEW_TABS_PCP.JSON :
          res = (
            <EvidenceContentJSON pageInfo={pageData} />
          )
          break;
          default:
            res = <></>
      }
      setComponent(res);
    }
  }, [pageInfo, searchKeywords, selectedLegends, selectedTab, loading]);

  return <div className="e-content">{component}</div>;
};
