import { Button, Table } from "antd";
import React from "react";
import { evidenceViewerService } from "../../../../api";

interface KeyValueProps {
  values: KeyValue[] | any;
  fileId: string;
}

export interface KeyValue {
  key: string;
  values: string[] | Procedure[] | Diagnosis[];
}

interface Procedure {
  procedureCode: string;
  dateOfServiceStart: string;
  dateOfServiceEnd: string;
}

interface Diagnosis {
  diagnosisCode: string;
  diagnosisQualifier: string;
}

const isKeyValue = (data: any): data is KeyValue => {
  return typeof data === "object" && data !== null && "key" in data;
};

const columns = [
  {
    title: "Key",
    dataIndex: "key",
    key: "key",
  },
  {
    title: "Values",
    dataIndex: "values",
    key: "values",
    render: (values: string | Procedure[] | Diagnosis[]) => {
      if (Array.isArray(values)) {
        if (values.length && "procedureCode" in values[0]) {
          // Procedures
          return (
            <ul>
              {values.map((procedure, index) => (
                (procedure as Procedure).procedureCode && (
                  <li key={index}>Procedure Code: {(procedure as Procedure).procedureCode}</li>
                )
              ))}
            </ul>
          );
        } else if (values.length && "diagnosisCode" in values[0]) {
          // Diagnoses
          return (
            <ul>
              {values.map((diagnosis, index) => (
                (diagnosis as Diagnosis).diagnosisCode && (
                  <li key={index}>Diagnosis Code: {(diagnosis as Diagnosis).diagnosisCode}</li>
                )
              ))}
            </ul>
          );
        } else {
          // Other array types - ignore
          return null;
        }
      }

      return <p>{values}</p>;
    },
  },
];

const KeyValueComponent: React.FC<KeyValueProps> = ({ values, fileId }) => {
  const handleDownloadSummary = async () => {
    const csvString = await evidenceViewerService.downloadSummary(fileId);
    const blob = new Blob([csvString], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "summary.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Table className="summaryTable" dataSource={values} columns={columns} pagination={false} />
      <Button className="outline" onClick={handleDownloadSummary}>
        Download Summary
      </Button>
    </div>
  );
};

export default KeyValueComponent;
