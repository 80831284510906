import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { PageWrapper, EvideceViewerAside, EvidenceViewerContainer } from "../containers";
import { useFetchEvidenceViewer, useFetchSearchResult } from "../hooks";
import "../containers/evidence-viewer/evidence-viewer-container/evidence-viewer-container.scss";
import { NotAccessibleFallback } from "../components";
import { COPILOTS_NAME, DOCUMENT_STATUS, openNotificationWithIcon } from "../utils";

const EvidenceViewer = () => {
  const location = useLocation();
  const copilotName = location?.state?.data;
  const status = location?.state?.status;

  const [searchParams] = useSearchParams();
  const { fetchSearchResults, searchLoading, searchResult } = useFetchSearchResult();
  const [pageSearchKey, setPageSearchKey] = useState<string | null>(null);
  const [searchKeywords, setSearchKeywords] = useState<string | null>(null);

  const { fileId, initialSearchKeywords, multipleFiles, tileType } = useMemo(() => {
    setSearchKeywords(searchParams.get("search"));
    return {
      fileId: searchParams.get("fileId")?.toLowerCase() || "",
      initialSearchKeywords: searchParams.get("search"),
      multipleFiles: searchParams.get("multipleFiles"),
      tileType: searchParams.get("tile_type"),
    };
  }, [searchParams]);

  const {
    fetchFile,
    fetchPage,
    currPage,
    fileData,
    occurencesList,
    pageLoading,
    searchOccurenceLoading,
    pageData,
    setCurrPage,
    fetchSearchOccurences,
    isAuthorized,
    isTiffFile,
    pegaPageData,
    angData,
    fetchPegaPage,
  } = useFetchEvidenceViewer({ fileId, searchKeywords });

  useLayoutEffect(() => {
    if (tileType) {
      const find = occurencesList.findIndex((ele) => ele.pageNumber === currPage);
      if (find !== -1) {
        setPageSearchKey(occurencesList[find].content.slice(0, tileType === "lifestyle" ? 14 : 20));
      }
    }
  }, [currPage, occurencesList, tileType]);

  useEffect(() => {
    if (multipleFiles === "true" && !searchResult.data) {
      fetchSearchResults("?limit=1000&offset=0");
    }
  }, [fetchSearchResults, multipleFiles, searchResult.data]);

  useEffect(() => {
    if (fileId) {
      fetchFile(copilotName);
    }
  }, [fetchFile, fileId]);

  useEffect(() => {
    if( status && status.toUpperCase() === DOCUMENT_STATUS.PROCESSING)
    {
      openNotificationWithIcon("","The document is getting processed","info");
      return;
    }
    if (fileId) {
      fetchPage(copilotName);
    }
  }, [fetchPage, fileId]);

  useEffect(() => {
    if (fileId) {
      fetchPegaPage(copilotName);
    }
  }, [fetchPegaPage, fileId]);

  useEffect(() => {
    if (((searchKeywords && searchKeywords.length > 0) || tileType) && fileId)
      fetchSearchOccurences(tileType);
  }, [fetchSearchOccurences, fileId, searchKeywords, tileType]);

  if (!isAuthorized) {
    return <PageWrapper pageContainerChildren={<NotAccessibleFallback />} />;
  }

  const renderPageContent = () => {
    if (!isAuthorized) {
      return <PageWrapper pageContainerChildren={<NotAccessibleFallback />} />;
    }

    const commonProps = {
      currPage,
      evidenceContentLoading: pageLoading || searchLoading,
      fileId,
      pageData,
      pageSearchKey,
      pdfUrl: fileData.url,
      searchKeywords,
      isTiffFile,
      fileUrl: fileData.url,
      setCurrPage,
      pegaPageData,
      copilotName,
      angData,
    };

    if (
      [COPILOTS_NAME.CCA, COPILOTS_NAME.ANG_INSIGHTS, COPILOTS_NAME.UM, COPILOTS_NAME.PCP, COPILOTS_NAME.PEGA_PRIOR_AUTH].includes(copilotName)
    ) {
      return <PageWrapper pageContainerChildren={<EvidenceViewerContainer {...commonProps} />} />;
    }

    return (
      <PageWrapper
        pageLeftAsideChildren={<EvidenceViewerContainer {...commonProps} />}
        pageContainerChildren={
          <EvideceViewerAside
            {...{
              ...commonProps,
              searchLoading,
              searchResult,
              setSearchKeywords,
              tileType,
              searchResultOccurrenceLoading: searchOccurenceLoading || searchLoading,
              occurencesList,
              initialSearchKeywords,
            }}
          />
        }
      />
    );
  };

  return <> {renderPageContent()} </>;
};

export default EvidenceViewer;
